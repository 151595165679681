import React, { useState, useEffect } from 'react';
import PhoneNumberDialog from '../dialogs/PhoneNumberDialog';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/SummaryOptions.scss';
import InfoButton from './InfoButton.js';

const SummaryCauses = ({ message,setToastMessage, sendMessage, disabled, setSubmitted, HoverColor }) => {
    const [selected, setSelected] = useState('');
    const [buttonValue, setButtonValue] = useState('');
    const [openPhoneNumberDialog, setOpenPhoneNumberDialog] = useState(false);
    const [editing, setEditing] = useState(false);

    const recLabel = message?.data?.[2]?.summaryCardContent?.recommendedHeaderText
        ? message.data[2].summaryCardContent.recommendedHeaderText
        : '';

    const header = message.data[2].summaryCardContent.careTypeHeader
        ? message?.data?.[2]?.summaryCardContent?.careTypeHeader
        : '';

    const isDisabled = disabled;

    const triageLevel = message?.data?.[0]?.triageMapping?.triagelevel
        ? message.data[0].triageMapping.triagelevel.toLowerCase()
        : 'unknown';

    const formatCode = message?.data?.[0]?.FormatCode
        ? message.data[0].FormatCode.toLowerCase()
        : 'unknown';

    const infoMean = (message?.data?.[0]?.infoMeaning)
    ? message.data[0].subHeaderText
    : 'What does this mean?';

    const hasSlots = message?.data?.[0]?.hasSlots
        ? message.data[0].hasSlots
        : false;

    const questionId = message?.data?.[0]?.question_id
        ? message.data[0].question_id
        : -1;

    // Recommend eVisit if no virtual visit slot available
    const recTriageLevel = ((triageLevel === 'virtual_care') && !hasSlots)
        ? 'evisit'
        : triageLevel;

    const phoneCardData = message && message.data && message.data.filter(data => { return data && data.type === 'phoneNumberCard' });

    const phoneCardMessage = phoneCardData.length
        ? phoneCardData[0]
        : {
            type: "phoneNumberCard",
            text: "",
            data: []
        };

    const buttons = message?.data?.[2].careOptionsData?.careOptions
        ? message.data[2].careOptionsData.careOptions
        : [];

    const buttonPress = (button) => {
        setSelected(button.value);

        if (button.value.toLowerCase() === 'contactme') {
            setButtonValue(button.value);
            setOpenPhoneNumberDialog(true)
            return;
        }

        setSubmitted(true);
        let value = button.value || 'none';

        editing
            ? sendMessage('message', `##edit@${questionId}@${value}`, `##edit@${questionId}@${value}`)
            : sendMessage('message', value, value);
    }

    const clearSelection = () => {
        setSelected('');
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    useEffect(() => {
        if (message.unsubmit) {
            setSelected('');
            setSubmitted(false);
            setEditing(true);
            message.unsubmit = false;
        }
    })

    return (
        <>
        {
            buttons.length!==0
            ? 
                <div className='options' >
                    <div className={'header ' + formatCode} tabIndex={isDisabled?"-1":"0"}>
                        <h2 className='headerText' >{getMarkDown(header)}</h2>
                    </div>

                    {
                        buttons.map((button, index) => {
                            return (button.value.toLowerCase() === 'VirtualCare' && !hasSlots) ||
                                (
                                    <div key={button.value}>
                                        {
                                            (button.recommended) &&
                                            <div className={isDisabled ? 'disabled recommended-tab' : 'recommended-tab'}>
                                                {recLabel}
                                            </div>
                                        }
                                        <button
                                            className={(selected === button.value) ? 'selected single-select btn-inline' : 'single-select btn-inline'}
                                            onClick={(e) => buttonPress(button)}
                                            disabled={isDisabled}
                                        >
                                            <input type="radio" name="care-type" style={{display: 'none'}} id={button.displayText.toLowerCase()} /> 
                                            <label htmlFor={button.displayText.toLowerCase()}>{button.displayText}</label>
                                        </button>
                                        {
                                            button.explication &&
                                            <InfoButton
                                                disabled={isDisabled}
                                                header={infoMean}
                                                explication={button.explication.text}
                                                onShowInfo={setToastMessage}
                                            />
                                        }
                                    </div>
                                )
                        })
                    }

                    <PhoneNumberDialog
                        open={openPhoneNumberDialog}
                        setOpen={setOpenPhoneNumberDialog}
                        message={phoneCardMessage}
                        sendMessage={sendMessage}
                        clearParentSelection={clearSelection}
                        setSubmitted={setSubmitted}
                        value={buttonValue}
                        disabled={disabled}
                        HoverColor={HoverColor}
                    />
                </div >
            :
            <></>
        }
        </>
    );
};

export default SummaryCauses;
