import React, { useEffect, useState, createRef } from 'react';
import { ReactComponent as ZippyPlus } from '../../../assets/zippy-plus.svg';
import { ReactComponent as ZippyMinus } from '../../../assets/zippy-minus.svg';
import { ReactComponent as Zippyarrow } from '../../../assets/zippyarrow.svg';
import '../../../stylesheets/triagebot/Zippy.scss';

const Zippy = ({ title, open, onClick, disabled, children, animate = true, onHeightChange, type }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);

    const contentsRef = createRef();

    const zippyType = type
        ? type
        : 'bar'

    const handleClick = () => {
        if (disabled) return;

        document.activeElement.blur();

        onClick instanceof Function
            ? onClick()
            : setIsOpen(!isOpen);

        if (onHeightChange instanceof Function)
            onHeightChange(isOpen ? 0 : maxHeight);
    }

    const getMaxHeight = (contentsRef) => {
        // We need to check the height periodically for case where the mutated content's size is animating.
        for (let t = 0; t < 1001; t += 250) {
            setTimeout(() => {
                setMaxHeight(contentsRef.clientHeight);
            }, t)
        }
    }

    useEffect(() => {
        setIsOpen(open);
    }, [open])

    useEffect(() => {
        if (!MutationObserver) return;

        const ref = contentsRef.current;
        const observer = new MutationObserver(() => getMaxHeight(ref));
        observer.observe(contentsRef.current, { attributes: true, childList: true, subtree: true });

        return () => observer.disconnect();
    }, [])

    return (
        <div className='zippy' >
            <button
                className={(isOpen ? 'open ' : 'closed ') + zippyType}
                onClick={handleClick}
                disabled={disabled}
            >
                <div className='icon'>
                    {
                        (type === 'text') &&
                        (isOpen ? <ZippyMinus /> : <ZippyPlus />)
                    }
                    {
                        (zippyType === 'bar') && <Zippyarrow />
                    }

                </div>
                {title}
            </button>
            <div className={animate ? 'animator animate' : 'animator'} style={isOpen ? { minHeight: maxHeight, maxHeight: maxHeight } : { minHeight: 0, maxHeight: 0, visibility: 'hidden'}}>
                <div
                    ref={contentsRef}
                    className={isOpen ? 'contents open' : 'contents closed'}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Zippy;
