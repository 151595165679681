import React from "react";
import { getMarkDown } from "../utils";
import '../../../stylesheets/triagebot/EVisitToastBody.scss';

const EVisitToastBody = ({ message }) => {

    //message[0]
    const MessageProvider = message[0].definition; 
    const provheader = MessageProvider.header;
    const provDesc = MessageProvider.description;

    //section[0]
    const provSection0title = MessageProvider.sections[0].title;
    const provSection0Para = MessageProvider.sections[0].paragraphs;

    // const provPara1 = provSection0Para[0].paragraph[0].text;
    // const provPara2 = provSection0Para[0].paragraph[1].text;
    // const provSection0para1 = `${provPara1} ${provPara2}`;

    const provSection0para2 = provSection0Para[1].paragraph[0].text;

    //section[1]
    const provSection1title = MessageProvider.sections[1].title;
    const Section1para = MessageProvider.sections[1].paragraphs;

    const provSection1Para1 = Section1para[0].paragraph[0].text;
    const provSection1Para2 = Section1para[1].paragraph[0].text;
    const provSection1Para3 = Section1para[2].paragraph[0].text;


    // message[1]
    const TermsService = message[1].definition;
    const termsheader = TermsService.header;
    const termsDesc = TermsService.description;
    const termsSubHeader = TermsService.subHeader;

    const termsSection = TermsService.sections;
    const termsSec0title = termsSection[0].title;
    //section[0]
    const termsParagraphs0 = termsSection[0].paragraphs;
    const termsSec0Para1 = termsParagraphs0[0].paragraph[0].text;
    const termsSec0Para2 = termsParagraphs0[1].paragraph[0].text;
    const termsSec0Para3 = termsParagraphs0[2].paragraph[0].text;
    
    //section[1]
    const termsSec1title = termsSection[1].title;
    const termsParagraphs1 = termsSection[1].paragraphs;
    const termsSec1Para1 = termsParagraphs1[0].paragraph[0].text;


    const input = `<div> This should only be used for non-urgent medical conditions, as it may take up to 24 hours to receive a response. 
    <strong>If you need urgent medical care, please contact your clinic by phone or find a nearby urgent care center.</strong> 
    <strong>For medical emergencies, call 911 immediately.</strong>
    </div>`

    return (
        <div style={{lineHeight: '24px'}} className="EVisitToastBody">
            <div className="header">{getMarkDown(provheader)}</div>
            <div className="provdesc">{getMarkDown(provDesc)}</div>
            {/* section 0 */}
            <div className="provSection0title"> {getMarkDown(provSection0title)}</div>

            <div className="provSection0para1">{getMarkDown(input)}</div>

            <div className="provSection0para2">{getMarkDown(provSection0para2)}</div>
            {/* section 1 */}
            <div className="provSection1title">{getMarkDown(provSection1title)}</div>
            <div className="provSection1Para1">{getMarkDown(provSection1Para1)}</div>
            <div className="provSection1Para2">{getMarkDown(provSection1Para2)}</div>
            <div className="provSection1Para3">{getMarkDown(provSection1Para3)}</div>


            <div className="header">{getMarkDown(termsheader)}</div>
            <div className="termsDesc">{getMarkDown(termsDesc)}</div>
            <div className="termsSubHeader">{getMarkDown(termsSubHeader)}</div>

            <div className="termsSec0title">{getMarkDown(termsSec0title)}</div>
            <div className="termsSec0Para1">{getMarkDown(termsSec0Para1)}</div>
            <div className="termsSec0Para2">{getMarkDown(termsSec0Para2)}</div>
            <div className="termsSec0Para3">{getMarkDown(termsSec0Para3)}</div>

            <div className="termsSec1title">{getMarkDown(termsSec1title)}</div>
            <div className="termsSec1Para1">{getMarkDown(termsSec1Para1)}</div>


        </div>
    )
}


export default EVisitToastBody;