/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef } from 'react';
import * as ProgressBarSVG from "../../assets/Progressbar";
import '../../stylesheets/triagebot/ProgressBar.scss';

const ProgressBar = ({ progressName, size }) => {
    const progressMap = {
        start: { completed: .01, display: 'Warming up' },
        welcome: { completed: .05, display: 'Welcome' },
        disclaimer: { completed: .10, display: 'Terms' },
        emergency: { completed: .15, display: 'Emergency' },
        symptoms: { completed: .20, display: 'Symptoms' },
        care_plan: { completed: .25, display: 'Plans for care' },
        risk_factors: { completed: .30, display: 'Possible conditions' },
        medical_history: { completed: .40, display: 'Current conditions' },
        red_flag: { completed: .45, display: 'Related symptoms' },
        related_symptoms: { completed: .50, display: 'Additional symptoms' },
        interview: { completed: .60, display: 'Interview' },
        freetext: { completed: .80, display: 'Comments' },
        triage_complete: { completed: .90, display: 'Plan for treatment' },
        treatmentplan: { completed: 0.90, display: 'Plan for treatment' },
        scheduleappointment: { completed: 0.95, display: 'Scheduling' },
        provider: { completed: .85, display: 'Choose provider' },
        nps: { completed: 1, display: 'Thank you' },
        comment: { completed: 1, display: 'Rate us' },
        'triage_complete-lifethreatening': { completed: 1, display: 'Call 911' },
        timeout : {completed: 1, display: 'Time out' },
        error : {completed: 1, display: 'Error' },
        triage_complete_emergency : {completed : 1, display : 'Emergency room'},
        triage_complete_call911 : {completed : 1, display : 'Call 911'},
        thankyou: { completed: 1, display: 'Thank you' },
        pcpprovider : {completed: 0.15, display : 'Select a provider'},
        schedulingtypeselection : {completed: 0.95, display : 'Scheduling' },
        evisittos : {completed: 0.95, display : 'E-visit' },
        multi_schedule_appointment : {completed: 0.95, display: 'Scheduling' },
        confirmation : {completed: 1, display: 'Thank you' }
    }

    const progressData = useRef();
    if (!progressName && !progressData.current)
        progressData.current = progressMap['start'];

    if (progressMap[progressName.toLowerCase()])
        progressData.current = progressMap[progressName.toLowerCase()];

    const barLength = size * progressData.current.completed;
    const done = progressData.current.completed === 1;

    useEffect(() => {
        if (!done) {
            document.getElementById("ProgressBarMeter").setAttribute("d", `M0 2C0 0.895431 0.895431 0 2 0H${barLength}V4H2C0.895431 4 0 3.10457 0 2V2Z`)
            document.getElementById("progress-title").innerHTML = (progressData.current.completed * 100) + '% Completed';
        }
    })

    return (
        <div className='progress-container'>
            <div className='label' style={{ width: size }}>
                {progressData.current.display}
            </div>
            {
                done
                    ? <ProgressBarSVG.progress100 style={{ width: size}} aria-labelledby="progress-title" role="img" />
                    : <ProgressBarSVG.progress1 style={{ width: size }} aria-labelledby="progress-title" role="img" />
            }
        </div>
    );
}

export default ProgressBar;
