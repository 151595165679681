import React, {useState} from "react";
import { getMarkDown, useDetectMobile } from "../utils";
import '../../../stylesheets/triagebot/EVisitTos_Summary.scss';
import Footer from "./Footer";
import EVisitToastBody from '../../triagebot/dialogs/EVisitToastBody';

const EVisitTos_Summary = ({ message, sendMessage, setToastMessage }) => {

    const [submitted, setSubmitted] = useState(false);

    const isDisabled = submitted;

    const headerText = message?.data[0]?.headerText;
    const BeforeData = message?.data[1]?.tosData;
    const feeHeader = message?.data[1]?.feesHeader;
    const FeesData = message?.data[1]?.feesData;
    const popupLinkText = message.data[1]?.popupLinkText;
    const buttons = message?.buttons;

    const agreeButton = buttons[0].displayText;
    const cancelButton = buttons[1].displayText;

    const {isMobile} = useDetectMobile();

    //pop up data
    const popUpData = message?.data[1]?.popupMessageData;

    if (typeof setToastMessage !== 'function')
        setToastMessage = () => { };

    const showToastMessage = () => {
        setToastMessage({
            type: "EVisit",
            header: '',
            body: (
                <EVisitToastBody
                  message={popUpData}
                /> )
        })
    }

    const cancelPress = ()=>{
        sendMessage('rewind', 'summaryPlaceHolder', 'summaryPlaceHolder');
        sendMessage('message', 'return', 'return');
    }

    const SubmitPress=()=>{
        if(submitted) return;
        setSubmitted(true);
        sendMessage('message', 'Next', 'Next');
    }

    return (
        <div className={(isDisabled ? 'disabled ': '') + "EVisitTos card"  + (isMobile ? ' mobile' : '')} style={{paddingTop: '8px'}}>

            <h2 className="terms">{getMarkDown(headerText)}</h2>
            
            <div className="TermService">
                {getMarkDown(BeforeData)}
            </div>

            <div className="FeesHeader">
                {getMarkDown(feeHeader)}
            </div>

            <div className="FeesData"> {getMarkDown(FeesData)}</div>

            <div className='popupLink' onClick={ ()=> showToastMessage() }>{getMarkDown(popupLinkText)}</div>


            <Footer>
                <button
                    onClick={cancelPress}
                    className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                    disabled={isDisabled}
                >
                    {cancelButton}
                </button>
                <button
                    onClick={SubmitPress}
                    className='continue-button'
                    disabled={isDisabled}
                >
                    {agreeButton}
                </button>
            </Footer>
        </div>
    )
}

export default EVisitTos_Summary;