import React from 'react';
import '../../../stylesheets/triagebot/UrgentLocationToast.scss';
import { ReactComponent as PhoneIcon } from '../../../assets/phone.svg';
import { ReactComponent as PlaceIcon } from '../../../assets/place.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/schedule.svg';
import {getMarkDown, useDetectMobile} from "../utils";
//******************************************************* */

const UrgentLocationToast = ({ message }) => {
    const { isMobile } = useDetectMobile();

    return (
        <div className='Urgent-locations-toast'> 
            {
                message.map((location) => {
                    return (
                        <div className='field'>
                            <div className='name'> {location.name} </div>

                            <div className='place combine'>
                                <PlaceIcon className='icon' />
                                <div className='address'> {location.address?.[0] + ', '+ location.city + ', ' + location.state + ' ' + location.zip}</div>
                            </div>

                            <div className='call combine'>
                                <PhoneIcon className='icon' />
                                {
                                    isMobile
                                        ? <a className='phone' href={`tel:+1${location.phone}`}> {location.phone}</a>
                                        : <div className='phone'> {location.phone} </div>
                                }
                            </div>

                            <div className='schedule combine'>
                                <ScheduleIcon className='icon' />
                                <div className='week_days'>
                                    {
                                        location.hours.map((hour)=>{
                                            return (
                                                <div className='hours-in-week'> {hour} </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default UrgentLocationToast;