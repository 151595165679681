import React, { useState, } from "react";
import Zippy from './Zippy';
import TimePicker from './TimePicker';
import Footer from './Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/AppointmentPicker.scss';

//************************* */

const AppointmentPickerMessage = ({ message, sendMessage, disabled }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('all');
    const [openedZippy, setOpenedZippy] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [providerName, setProviderName] = useState('');

    const [hasError, setHasError] = useState(
        (message && message.data && message.data.length && message.data[0].errorInScheduling)
            ? message.data[0].errorInScheduling
            : false)

    if (!(sendMessage instanceof Function))
        sendMessage = () => { };

    const isDisabled = disabled || submitted;

    const isValid = selectedTime;

    const { isMobile } = useDetectMobile();
    
    const aemMeridiem = {
        All : message?.data?.[1]?.amPmTexts?.allText,
        AM  : message?.data?.[1]?.amPmTexts?.amText,
        PM  : message?.data?.[1]?.amPmTexts?.pmText
    }

    const header = (message.data && message.data.length)
        ? message.data[0].headerText || message.data[0].header
        : ''

    const subHeader = (message.data && message.data.length)
        ? message.data[0].subHeaderText
        : ''

    const errorMessage = (message && message.data && message.data.length && message.data[0].errorMessage)
        ? message.data[0].errorMessage
        : 'Error'

    const slotsMilli =  message?.data?.[1]?.allSlots?.[0]?.slots;

    const slotsDate = slotsMilli
        .filter((value) => { return (Number.isInteger(value)) })
        .map((millisec) => { return new Date(millisec) })

    const uniqueTitleDates = [...new Set(slotsDate.map((date) => { return getDateString(date) }))]

    const validDates = uniqueTitleDates.map((titleDate) => {
        return {
            displayDate: titleDate,
            times: slotsDate.filter((date) => { return getDateString(date) === titleDate })
        }
    })

    const getFilteredTimes = (times) => {
        switch (selectedFilter) {
            case 'am': return times.filter((times) => { return times.getUTCHours() < 12 });
            case 'pm': return times.filter((times) => { return times.getUTCHours() >= 12 });;
            default: return times;
        }
    };

    const getTitle = (uniqueDate, count) => {
        return (
            <span className='title'>
                <span>
                    {uniqueDate}
                </span>
                <span>
                    {count}
                    {isMobile || ' available'}
                </span>
            </span>
        )
    }

    const toggleZippies = (zippy) => {
        setOpenedZippy(zippy);
    }

    const setSelectedDateTime = (date, time) => {
        setSelectedDate(date);
        setSelectedTime(time);
        setHasError(false);
    }

    const cancelPress = () => {
        sendMessage('rewind', ['summaryPlaceHolder', 'emergency'], ['summaryPlaceHolder', 'emergency']);
        
        sendMessage('message', 'returnerr', 'returnerr');
    }

    const submitPress = (event, button) => {
        if (submitted) return;

        setSubmitted(true);

        sendMessage('message', selectedTime, selectedTime);
    }

    function getDateString(date) {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const month = months[date.getUTCMonth()];
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();

        return `${month} ${day}, ${year}`;
    }

    const handleChangeOutline = (value) => {
        setSelectedFilter(value);

        document.activeElement.blur();
    }

    const contactTag = message?.data?.[1]?.contactTag;

    const contactNum = contactTag?.match(/(?:[-]*\d){10,13}/g);
    const contactNumText = (contactNum && contactNum.length > 0 ) ? `tel:+1${contactNum[0]}` : '';
    const subHeaderSplitText = contactTag?.split(contactNum?.[0]);

    const nonPcpText = message?.data?.[1]?.nonPcpText;

    return (
        <div className={(isDisabled ? 'disabled ' : '') + (isMobile ? 'mobile ' : '') + 'desktop apt-picker card'}>
            <div className='content'>
                <div className='header'>
                    <h2 className="headerText">{getMarkDown(header)}</h2>
                    <div className='subheader'>
                        {getMarkDown(subHeader)}
                    </div>
                </div>
                {
                    !hasError ||
                    <div className='alert'>
                        {errorMessage}
                    </div>
                }
                <div className='body'>

                    <div className='filter-bar'>
                        <button
                            className={selectedFilter === 'am' ? 'selected' : ''}
                            onClick={() => handleChangeOutline('am')}
                            disabled={isDisabled}
                        >
                            {aemMeridiem.AM}
                        </button>
                        <button
                            className={selectedFilter === 'pm' ? 'selected' : ''}
                            onClick={() => handleChangeOutline('pm')}
                            disabled={isDisabled}
                        >
                            {aemMeridiem.PM}
                        </button>
                        <button
                            className={selectedFilter === 'all' ? 'selected' : ''}
                            onClick={() => handleChangeOutline('all')}
                            disabled={isDisabled}
                        >
                            {aemMeridiem.All}
                        </button>
                    </div>

                    {
                        validDates.map((date, i) => {
                            const filteredTimes = getFilteredTimes(date.times);
                            return (
                                <Zippy
                                    title={getTitle(date.displayDate, filteredTimes.length)}
                                    open={(openedZippy === i) && filteredTimes.length && !isDisabled}
                                    onClick={() => {toggleZippies(openedZippy === i ? -1 : i); setShowAll(false); }}
                                    disabled={!filteredTimes.length || isDisabled}
                                    key={date + i}
                                >
                                    <TimePicker
                                        displayTimes={filteredTimes}
                                        selectedTime={(selectedDate === date.titleDate) ? selectedTime : null}
                                        setSelectedTime={(time) => setSelectedDateTime(date.titleDate, time)}
                                        meridiem={selectedFilter}
                                        isMobile={isMobile}
                                        showAll={showAll}
                                        setShowAll={setShowAll}
                                        disabled={isDisabled}
                                        aemMeridiem={aemMeridiem}
                                        providerInfo={slotsMilli}
                                        setProviderName={(provider) => setProviderName(provider)}
                                        ProviderName={providerName}
                                    />
                                </Zippy>
                            )
                        })
                    }

                    {
                        contactTag
                            ?
                            (isMobile && subHeaderSplitText && subHeaderSplitText.length === 2) ?
                                <div style={{ paddingTop: '8px' }}>
                                    {subHeaderSplitText[0]}<a href={contactNumText} className={'contactTag'}>{contactNum?.[0]}</a>{subHeaderSplitText[1]}
                                </div>
                                :
                                <div style={{ paddingTop: '8px' }}>
                                    {getMarkDown(contactTag)}
                                </div>
                            :
                            <></>
                    }

                    {
                        nonPcpText 
                        ?
                            <div className="nonPcpText">
                                {nonPcpText}
                            </div>
                        :
                        <></>
                    }

                </div>
            </div>
            { submitted ||
                <Footer>
                    <button
                        onClick={cancelPress}
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        disabled={isDisabled}
                    >
                        Cancel
                </button>
                    <button
                        onClick={submitPress}
                        className='continue-button'
                        disabled={!isValid || isDisabled}
                    >
                        Confirm
                </button>
                </Footer>
            }
        </div>
    );
}

export default AppointmentPickerMessage;